export const MemberAgreementHtml = `
                <div class="modal-body">
                                    <div class="text-muted">
                                        <div class="mb10">
                                            <p>【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是使用须知、隐私保护和法律责任的相关条款。如您对协议有任何疑问，可向速调吧客服咨询。 速调吧隶属于南京百数来信息科技有限公司，是专业的市场调查网站。用户注册、登录、使用速调吧及相关产品等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">一、注册说明</p>
                                            <p>
                                                用户须完全同意下列所有服务条款并完成注册程序，才能成为速调吧的会员。除非另有明确规定，否则速调吧相关的一切新产品均无条件服从本协议。
                                            </p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">二、服务内容</p>
                                            <p>
                                                用户成为速调吧的正式会员后，将不定期的接收到调查问卷，会员可通过登录速调吧参与问卷。速调吧也将不定期地通过邮件与用户取得联系，邀请会员参与各种形式的市场调研。会员可以使用速调吧的金币奖励兑换奖品。
                                            </p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">
                                                三、个人账号管理
                                            </p>
                                            <p>
                                                1．速调吧的注册用户有义务妥善保管和使用在注册服务时获得的账号及密码，并为此账号及密码登入系统后所开始的一连串行为或活动负责。
                                            </p>
                                            <p>
                                                2．用户不应将账号及密码泄漏或提供第三人知悉或出借或转让与他人使用，如因用户自身过错或过失而导致账号或密码泄漏而给用户自身造成损失，用户应自行承担责任。
                                            </p>
                                            <p>
                                                3．若用户发现账号或密码遭他人非法使用或有异常使用的情形，应立即通知速调吧，并提交该账号为本人所有的有关证明，以便申请该账号的暂停使用。因此而造成的损失，速调吧不承担赔偿责任。
                                            </p>
                                            <p>
                                                4．速调吧的会员有义务严格按照个人实际情况来填写账号资料，如有发现不符合实际情况的账号，速调吧有权暂停或终止用户的账号，并拒绝现在或将来使用服务的全部或一部分。
                                            </p>
                                            <p>
                                                5．速调吧会员不得同时注册多个账号，速调吧将通过各种技术手段进行技术检查，当发现用户存在多个账号时,速调吧有权封停账号。
                                            </p>
                                            <p>
                                                6．用户注册速调吧账号后，如果长期不使用，速调吧有权删除其账号，以免造成资源浪费。
                                            </p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">
                                                四、用户使用须知
                                            </p>
                                            <p>
                                                特别提醒用户，使用互联网必须遵守国家有关的政策和法律，如刑法、国家安全法、保密法、计算机信息系统安全保护条例，保护国家利益;保护国家安全。对于违法或者不当使用互联网络而引起的一切责任，由用户负全部责任和连带责任。
                                            </p>
                                            <p>1．用户不得使用速调吧发送或者传播敏感信息或者违反国家法律制度的信息；用户不得使用速调吧发送或传播虚假、骚扰性、侮辱性、恐吓性、伤害性、挑衅性、庸俗性、淫秽色情性信息。</p>
                                            <p>2．速调吧和大多数因特网网站或应用一样，易受到各种安全问题的困挠，包括：个人资料被盗取，被不法分子利用，造成现实生活中的骚扰；被破译密码；下载安装的其他软件或者文件中含有各种病毒，威胁到个人计算机上信息和数据的安全，进而威胁到速调吧的使用。 </p>
                                            <p>3．用户应加强个人资料保护意识，以免对个人生活和正常使用速调吧服务造成不必要的影响。</p>
                                            <p>4．盗取他人用户账号，共享和传播非法和不良信息、文件，或利用网络通讯骚扰他人，均属于违法行为。</p>
                                            <p>5．违反规则处罚规定。(1)参加调查不认真，没有根据自己本人的意愿如实填写问卷，根据每份问卷的金币情况进行倒扣金币处罚。</p>
                                            <p>6．必须遵守问卷保密原则。速调吧会员必须严格遵守调查保密原则，不允许发布或转发答案、不允许复制问卷内容、不允许为他人做任何的提示，一但发现追究其法律责任。</p>
                                            <p>7．注册信息真实无误。用户应保证其提供给速调吧的资料均为真实无误，不得伪造或利用他人信息进行注册，该资料对于使用速调吧的服务及兑换各种奖品和现金至关重要。如因用户提供虚假资料或被他人获悉自己的注册资料，从而导致的损失由用户本人承担。</p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">五、隐私保护</p>
                                            <p>1．会员成功注册后，速调吧会把会员提供的信息储存在我们的安全数据库里。会员的个人资料会用于确保问卷参加者的比例具有与总人口的代表性，并使用于在个别特定的调查项目时确认出最适合的人选。</p>
                                            <p>2．速调吧将不会在未得到会员同意的情况下公开会员的个人识別资料，不会透过网上调查或以邀请方式来收集个人资料或透露会员的个人资料给他人。</p>
                                            <p>3．会员在加入速调吧后将默认为，速调吧及其合作伙伴可以在需要时（如邀请会员参加调查或向会员支付奖品等情况时），有权通过电子邮件、短信、电话等会员填写的联络资料联系会员。但是因为网络的非绝对安全性，我们会尽力使用技术手段来保护您的个人信息，但无法确保其绝对的安全性。</p>
                                            <p>4．速调吧收集所得的会员资料是网上调查和研究的其中一部分。这些资料将被综合分析，以集体或统计的报告形式传递给发布调查的公司。每份调查问卷所收集的数据其目的只为进行一次研究，绝对不做任何有关第三方的转发或网络传播！</p>
                                            <p>5．参加网上问卷调查，即表示会员承认和同意将其个人资料（例如会员在问卷中的答案及所提供的姓名、电邮地址等）用于市场调查、市场研究分析、內部管理、內部培训等，并同意履行速调吧用户协议。速调吧会员的联络资料只能在用来邀请会员参加调查、确认会员所提供的资料真实性或提供给会员有关支付奖金的资料时，通过电子邮件、短信、电话联系会员。会员参加速调吧的活动是自愿的，有权选择加入和退出。</p>
                                            <p>6．参与速调吧的调查，表示您已同意在符合以上的隐私保护条例下我们对会员个人资料的处理和向其他公司透露这些资料，以及您是14周岁或以上的中国公民。</p>
                                            <p>7．为了保护儿童，速调吧没有意愿邀请儿童加入会员。如果需要儿童参加的调查儿童须在成年人的监督下，并需遵从儿童本人的意愿回答问卷的相关问题。</p>
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">六、法律责任</p>
                                            <p>1．我们对速调吧中所显示的信息或资料的准确性、内容、完整性、合法性、可靠性、可操作性或可用性不承担任何责任。我们对速调吧中的任何信息或材料的删除、存储失败、错误提供或未及时提供不承担任何责任。我们对速调吧的安全性、可靠性、及时性和性能不做任何担保。我们对通过速调吧而获得的任何信息或建议不做任何担保。</p>
                                            <p>2．用户理解并同意，用户通过速调吧获取信息或数据完全出于自己的判断，由自己承担风险，同时将对由下载此类材料或数据而导致的计算机系统损坏或数据损失承担全部责任。除速调吧条款注明的服务条款外，其他一切因使用速调吧而引致之任何意外、疏忽、合约毁坏、诽谤、版权或其他知识产权侵犯及其所造成的损失（包括因下载而感染电脑病毒），本站不承担由此引发的法律责任。</p>
                                            <p>3．用户理解并同意，在反馈处理服务中，速调吧的客服及相关工作人员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意外，速调吧对决定免责。</p>
                                            <p>4．速调吧依法律规定承担基础保障义务，但无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</p>
                                            <p>5．任何单位或个人认为速调吧内容可能涉嫌侵犯，应准备好具有法律效应的证明材料，及时与速调吧取得联系，以便速调吧迅速做出处理。</p>
                                            <p>6．速调吧会员言论纯属个人观点，网站登载目的在于传递更多信息，并不代表速调吧赞同其观点和对其真实性负责，速调吧不承担由此引发的法律责任。</p>
                                            <p>7．凡以任何方式登陆速调吧或直接、间接使用速调吧资料者，视为自愿接受本协议各项条款的约束。本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。</p>
                
                                        </div>
                                        <div class="mb10">
                                            <p class="text-success">七、协议修改及其他</p>
                                            <p>
                                                1．上述协议条款的解释权和修改权归速调吧所有。
                                            </p>
                                            <p>2．速调吧保留随时修改本用户协议的权利，并鼓励您定期检查更新。会员如果不同意服务协议条款的修改，可以主动与我们取得联系或停止使用速调吧及其相关产品所提供的服务；如果用户继续使用速调吧及其相关产品，则视为用户已经接受服务协议条款的修改。 </p>
                                            <p>3．本协议条款和速调吧其他协议服务条款构成完整的协议。本协议条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
                                        </div>
                                    </div>
                                </div>`;

export const PrivacyAgreementHtml = `
                                <div class="text-muted">
                                <div class="mb10">
                                    <p>尊敬的用户，请您务必仔细阅读速调吧(以下简称“我们”)政策声明，并确定了解我们对您个人信息的处理规则。请您务必充分理解各条款内容，特别是以加粗、加下划线等形式提示您重点注意的条款。</p>
                                </div>
                                <div class="mb10">
                                    <p>除非您已充分阅读、完全理解并接受本隐私政策所有条款，否则您无权使用我们的的产品或服务。您点击“同意”或“下一步”，或您使用我们的产品或服务，或者以其他任何明示或者默示方式表示接受本隐私政策的，均视为您已阅读并同意签署本隐私政策。本隐私政策即在您与我们之间产生法律效力，成为对双方均具有约束力的法律文件。</p>
                                </div>
                                <div class="mb10">
                                    <p>如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本隐私政策，并特别注意未成年人使用条款。</p>
                                </div>
                                <div class="mb10">
                                    <p>如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。</p>
                                </div>
                                <div class="mb10">
                                    <p>速调吧非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息。</p>
                                </div>
                                <div class="mb10">
                                    <p>本政策将帮助您了解以下内容：</p>
                                    <p>
                                        &nbsp;1．我们如何收集您的个人信息
                                    </p>
                                    <p>
                                        &nbsp;2．我们如何使用 Cookie 和同类技术
                                    </p>
                                    <p>
                                        &nbsp;3．我们如何使用您的个人信息
                                    </p>
                                    <p>
                                        &nbsp;4．我们会如何共享、转让、公开披露您的个人信息
                                    </p>
                                    <p>
                                        &nbsp;5．我们如何保护和保存您的个人信息
                                    </p>
                                    <p>
                                        &nbsp;6．您如何管理个人信息
                                    </p>
                                    <p>
                                        &nbsp;7．未成年人信息的保护
                                    </p>
                                    <p>
                                        &nbsp;8．本政策的适用及更新
                                    </p>
                                    <p>
                                        &nbsp;9．如何联系我们
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">一、我们如何收集您的个人信息</p>
                                    <p>
                                        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：<strong>基本信息</strong>（包括个人姓名、生日、性别、住址、手机号码、电子邮箱）；<strong>个人身份信息</strong>（包括身份证、军官证、护照、驾驶证等）；<strong>人口学属性信息</strong>（教育程度、职业、收入以及其他市场调研行业需要收集的信息）；<strong>问卷作答信息</strong>（您在参与市场调查问卷时，需要作答问卷中的题目，您可能需要提供下述但不限于如下您的日常信息（产品认知，消费行为等等）；<strong>个人财产信息</strong>（积分、奖品、积分记录、兑换记录等信息）；<strong>个人上网记录</strong>（包括网站浏览记录、软件使用记录、点击记录）；<strong>个人常用设备信息</strong>（包括硬件型号、设备 MAC 地址、IP 地址、操作系统类型、浏览器信息、软件列表唯一设备识别码（如 IMEI、IDFA 等在内的描述个人常用设备基本情况的信息）；<strong>个人位置信息</strong>（精准定位信息、经纬度等）；
                                    </p>
                                    <p>
                                        1、 您直接提供的信息
                                    </p>
                                    <p>
                                        (1)注册
                                    </p>
                                    <p>
                                        在您注册本软件账户或使用本软件服务时，需要提供昵称、头像、手机号码、密码、生日、性别、省份、城市。如您不提供前述信息，可能无法注册账户或无法使用本软件服务。手机号码和密码是您登陆和使用本软件的必要条件，生日、性别、省份、城市信息是您参与市场调查问卷抽样的必须条件。
                                    </p>
                                    <p>
                                        人口学属性信息，是您参与市场调查问卷必要的前提条件，所以除注册信息外，您还需要进一步向我们提供包括但不限于您的如下属性信息（教育程度、职业、收入以及其他市场调研行业需要收集的信息）。
                                    </p>
                                    <p>
                                        (2)问卷作答
                                    </p>
                                    <p>
                                        市场调查问卷就是用科学的方法系统地搜集、记录、整理和分析有关市场的信息资料，从而了解市场发展变化的现状和趋势，为企业经营决策、广告策划、广告写作提供科学的依据。一份比较完善的问卷作答信息通常由以下几部分构成：
                                    </p>
                                    <p>
                                        a.被调查者的基本情况包括被调查者的年龄、性别、教育程度、职业、住址、收入等。
                                    </p>
                                    <p>
                                        b.调查内容本身是指所调查的具体项目，它是问卷的最重要的组成部分。
                                    </p>
                                    <p>
                                        c.调查问卷说明其内容主要包括填表目的和要求，被调查者注意事项，提交时间等。
                                    </p>
                                    <p>
                                        调查内容是用户主要填写的部分，例如：请问你使用过以下哪些品牌的牙膏？
                                    </p>
                                    <p>
                                        A 两面针 B 高露洁 C 雅植
                                    </p>
                                    <p>
                                        (3)兑换
                                    </p>
                                    <p>
                                        我们首先会核实您的积分合法性，比如是否存在通过作弊，盗取，冒领，重复领取等行为获得积分的情况，当发现积分有异常情况下我们有权拒绝您的兑换申请、并且扣减您非法所得的积分。
                                    </p>
                                    <p>
                                        当您使用积分兑换奖品时，根据奖品的差异，需要您提供相应的信息。首先兑换需要认证手机号码真实性，以便于通知您兑换相关信息。兑换卡券等虚拟奖品需要您提供 Email 信息， 以便您接收卡号/卡密等信息。兑换现金类奖品，需要您填写支付宝、身份证、真实姓名、且需要对身份证进行验证，因为我们需要向税务机关进行您的个人所得税申报。<strong>请您一定注意，我们向税务机关进行您的个人所得税申报会直接体现在您的个税记录中，如果您在领取低保，或者您的收入/个税记录与您的其它事项有关系，请切记谨慎操作，以免带来不必要的麻烦，我们不承担由此造成的您个人的任何损失以及任何责任。</strong>实物快递类奖品，至少需要您提供手机号码，收货人姓名，收货地址信息。
                                    </p>
                                    <p>
                                        2、自动收集的信息
                                    </p>
                                    <p>
                                        为了改进提高我们的产品和服务，同时为了更有针对性和准确的向您推送市场调研活动，以及满足市场调研的项目要求，您同意并授权我们收集您的如下信息：<strong>个人上网记录</strong>（包括网站浏览记录、软件使用记录、点击记录）；<strong>个人常用设备信息</strong>（包括硬件型号、设备 MAC 地址、IP 地址、操作系统类型、浏览器信息、软件列表唯一设备识别码（如 IMEI、IDFA 等在内的描述个人常用设备基本情况的信息）；<strong>个人位置信息</strong>（精准定位信息、经纬度等）；
                                    </p>
                                    <p>
                                        3、第三方信息
                                    </p>
                                    <p>
                                        您可能通过微博、微信、QQ 支付宝等第三方帐号登录本软件，我们可能从第三方获取您授权共享的帐号信息（标识 ID、头像、昵称等），并在您同意本隐私政策后将您的第三方账户与您的本软件帐号绑定，使您可以通过第三方帐号直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">二、我们如何使用 Cookie 和同类技术</p>
                                    <p>
                                        您使用我们的服务时，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。我们使用 Cookie 主要包含以下几种情况：
                                    </p>
                                    <p>
                                        1、判断注册用户登录状态
                                    </p>
                                    <p>
                                        2、提升服务/产品质量，向您提供个性化需求
                                    </p>
                                    <p>
                                        3、市场调查问卷项目需求
                                    </p>
                                    <p>
                                        我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">
                                        三、我们如何使用您的个人信息
                                    </p>
                                    <p>
                                        1．使用我们的服务时用于身份验证，用户服务和安全防范等；
                                    </p>
                                    <p>
                                        2．个性化服务以及帮助我们设计新服务，提升现有服务体验；
                                    </p>
                                    <p>
                                        3．市场调研、<strong>广告监测分析以及相关的</strong>数据研究和数据分析；
                                    </p>
                                    <p>
                                        4．日常运营活动；
                                    </p>
                                    <p>
                                        5．根据法律法规或监管要求向相关部门进行报告。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">
                                        四、我们会如何共享、转让、公开披露您的个人信息
                                    </p>
                                    <p>
                                        1、共享
                                    </p>
                                    <p>
                                        我们不会在未经您同意或授权的情况下将您的个人信息提供给第三方。但是，经您确认同意， 我们可在以下情况下共享您的个人信息。
                                    </p>
                                    <p>
                                        （1）事先获得您明确的同意或授权；
                                    </p>
                                    <p>
                                        （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
                                    </p>
                                    <p>
                                        （3）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
                                    </p>
                                    <p>
                                        （4）应您需求为您处理您与他人的纠纷或争议；
                                    </p>
                                    <p>
                                        （5）基于学术研究而使用；
                                    </p>
                                    <p>
                                        共享给我们的关联公司：在本政策声明的使用目的范围内，您的个人信息可能会与我们的关联公司共享。作为一项政策，我们只会共享必要的信息。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
                                    </p>
                                    <p>
                                        我们可能会向合作伙伴等第三方共享您的某些信息，<strong>如与第三方广告监测服务商共享您的唯一设备识别码用于市场调研、广告监测分析等。</strong>我们仅会出于特定、明确而合法的目的处理您的个人信息，并且只会共享提供服务所必要的信息。同时， 我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
                                    </p>
                                    <p>
                                        2、转让
                                    </p>
                                    <p>
                                        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
                                    </p>
                                    <p>
                                        （1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
                                    </p>
                                    <p>
                                        （2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
                                    </p>
                                    <p>
                                        3、公开披露
                                    </p>
                                    <p>
                                        我们仅会在以下情况下，且采取符合法律和业界标准的安全防护措施的前提下，才会公开披露您的个人信息
                                    </p>
                                    <p>
                                        （1）获得您的明确同意；
                                    </p>
                                    <p>
                                        （2）基于法律法规、法律程序、诉讼或政府主管部门强制性要求。但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">五、我们如何保护和保存您的个人信息</p>
                                    <p>
                                        我们重视个人信息安全。我们采取互联网业内标准做法来保护您的个人信息，防止信息遭到未经授权访问、披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
                                    </p>
                                    <p>
                                        我们会尽全力保护您的个人信息，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
                                    </p>
                                    <p>
                                        在发生个人信息安全事件的场合，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
                                    </p>
                                    <p>
                                        我们仅会在达到本政策所述目的所必需的时限内保存您的个人信息，但为了遵守适用的法律法规、法院判决或裁定、其他有权机关的要求、维护公共利益等目的，我们可能会将个人信息保存时间予以适当延长。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">六、您如何管理个人信息</p>
                                    <p>
                                        我们将尽一切可能采取适当的技术手段，保证收集到的有关于您的个人信息的准确性，并保证及时进行更新。
                                    </p>
                                    <p>
                                        1、PC 端，登录后，在<strong>账号设置</strong>中可以修改以下三类信息：账号信息，个人资料和兑换信息。
                                    </p>
                                    <p>
                                        2、手机端，登录后，在<strong>我的</strong>里面可以修改各项个人信息。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">七、未成年人信息的保护</p>
                                    <p>
                                        1、如果您是 18 周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您家长或法定监护人的书面同意。我们根据国家相关法律法规的规定保护未成年人的个人信息。
                                    </p>
                                    <p>
                                        2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">八、本政策的适用及更新</p>
                                    <p>
                                        本产品所有服务均适用政策明。
                                    </p>
                                    <p>
                                        发生下列重大变化情形时，我们会适时对本政策进行更新：
                                    </p>
                                    <p>
                                        &nbsp;（1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
                                    </p>
                                    <p>
                                        &nbsp;（2）收集、存储、使用个人信息的范围、目的、规则发生变化；
                                    </p>
                                    <p>
                                        &nbsp;（3）对外提供个人信息的对象、范围、目的发生变化；
                                    </p>
                                    <p>
                                        &nbsp;（4）您访问和管理个人信息的方式发生变化；
                                    </p>
                                    <p>
                                        &nbsp;（5）数据安全能力、信息安全风险发生变化；
                                    </p>
                                    <p>
                                        &nbsp;（6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
                                    </p>
                                    <p>
                                        &nbsp;（7）其他可能对您的个人信息权益产生重大影响的变化。
                                    </p>
                                    <p>
                                        对于政策明发生重大变化，我们会通过不同渠道向您发送变更通知，包括但不限于网站公示、私信通知、App 推送等方式。
                                    </p>
                                </div>
                                <div class="mb10">
                                    <p class="text-success">九、如何联系我们</p>
                                    <p>
                                        当您对本政策有任何疑问，可以发送邮件至team#baishulai.com咨询（请将#号替换成@符号），我们将及时解决您的问题。
                                    </p>
                                    <p>
                                        <small>注：本《隐私政策》版本于2019年1月1日生效。</small>
                                    </p>
                                </div>
                            </div>
                                `;
