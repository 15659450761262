const language = process.env.VUE_APP_I18N || "zh-CN";
const customLangs = require(`assets/i18n/${language}.json`);

export default [
  {
    path: "/",
    name: "home",
    component: () => import("views/home"),
    alias: "",
    meta: {
      pagetitle: customLangs["page_title"],
      actived: "home",
      requiresAuth: false,
    },
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("views/pages"),
    children: [
      {
        path: "help/index.html",
        redirect: "help",
      },
      {
        path: "activities/index.html",
        redirect: "activities",
      },
      {
        path: "changes/index.html",
        redirect: "changes",
      },
      {
        path: "activities",
        name: "activities",
        component: () => import("views/pages/activities"),
        meta: {
          pagetitle: customLangs["page_title11"],
          actived: "activities",
          requiresAuth: false,
        },
        alias: "/activities",
      },
      {
        path: "center",
        name: "center",
        component: () => import("views/pages/center"),
        meta: {
          pagetitle: customLangs["page_title1"],
          actived: "center",
          requiresAuth: true,
        },
        alias: "/center",
        children: [
          {
            path: "",
            name: "homepage",
            component: () => import("views/pages/center/home-page"),
            meta: {
              pagetitle: customLangs["page_title1"],
              actived: "center",
              subactived: "1",
              requiresAuth: true,
            },
          },
          {
            path: "tasks-record",
            name: "tasks-record",
            component: () => import("views/pages/center/tasks-record"),
            meta: {
              pagetitle: customLangs["page_title2"],
              actived: "center",
              subactived: "2",

              requiresAuth: true,
            },
          },
          {
            path: "exchange-record",
            name: "exchange-record",
            component: () => import("views/pages/center/exchange-record"),
            meta: {
              pagetitle: customLangs["page_title3"],
              actived: "center",
              subactived: "3",

              requiresAuth: true,
            },
          },
          {
            path: "medal",
            name: "medal",
            component: () => import("views/pages/center/medal"),
            meta: {
              pagetitle: customLangs["page_title15"],
              actived: "center",
              subactived: "4",
              requiresAuth: true,
            },
          },
          {
            path: "messages",
            name: "messages",
            component: () => import("views/pages/center/messages"),
            meta: {
              pagetitle: customLangs["page_title4"],
              actived: "center",
              subactived: "5",

              requiresAuth: true,
            },
          },
          {
            path: "gold-record",
            name: "gold-record",
            component: () => import("views/pages/center/gold-record"),
            meta: {
              pagetitle: customLangs["page_title5"],
              actived: "center",
              subactived: "6",

              requiresAuth: true,
            },
          },
          {
            path: "setting",
            name: "setting",
            component: () => import("views/pages/center/setting"),
            meta: {
              pagetitle: customLangs["page_title6"],
              actived: "center",
              subactived: "7",

              requiresAuth: true,
            },
          },
          {
            path: "recommend",
            name: "recommend",
            component: () => import("views/pages/center/recommend"),
            meta: {
              pagetitle: customLangs["page_title7"],
              actived: "center",
              subactived: "8",

              requiresAuth: true,
            },
          },
          {
            path: "credit",
            name: "credit",
            component: () => import("views/pages/center/credit"),
            meta: {
              pagetitle: customLangs["page_title8"],
              actived: "center",
              subactived: "9",

              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "changes",
        name: "changes",
        component: () => import("views/pages/changes"),
        meta: {
          pagetitle: customLangs["page_title9"],
          actived: "changes",
          requiresAuth: false,
        },
        alias: "/changes",
      },
      {
        path: "help",
        name: "help",
        component: () => import("views/pages/help"),
        meta: {
          pagetitle: customLangs["page_title10"],
          actived: "help",
          requiresAuth: false,
        },
        alias: "/help",
      },

      {
        path: "activities/:id",
        name: "activitiedetail",
        component: () => import("views/pages/activities/detail"),
        meta: {
          pagetitle: customLangs["page_title11"],
          actived: "activities",
          requiresAuth: false,
        },
      },
      {
        path: "tasks",
        name: "tasks",
        component: () => import("views/pages/tasks"),
        meta: {
          pagetitle: customLangs["page_title12"],
          actived: "tasks",
          requiresAuth: true,
        },
        alias: "/tasks",
      },
      {
        path: "survey/:id",
        name: "survey",
        component: () => import("views/pages/survey"),
        meta: {
          pagetitle: customLangs["page_title13"],
          actived: "tasks",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/subPage",
    name: "subPage",
    component: () => import("views/subPage"),
    meta: {
      pagetitle: customLangs["page_title"],
      requiresAuth: false,
    },
  },
  {
    path: "/brower-update",
    name: "browerUpdate",
    component: () => import("views/brower-page"),
    meta: {
      pagetitle: customLangs["page_title14"],
      requiresAuth: false,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];
