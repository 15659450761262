import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  // TODO: 初始状态
  loading: "",
  account: {},
  userInfo: {},
  isLogin: false,
  dialogVisible: {
    login: false,
    forgot: false,
    editPwd: false,
    shareSurvey: false,
    editAlipay: false,
    editMobile: false
  },
  lang: {
    code: "代码"
  },
  recommendId: ""
};

const getters = {
  loading(state) {
    return state.loading;
  },
  account(state) {
    return state.account;
  },
  userInfo(state) {
    return state.userInfo;
  },
  isLogin(state) {
    return state.isLogin;
  },
  getLang(state) {
    return state.lang;
  },
  dialogVisible(state) {
    return state.dialogVisible;
  },
  recommendId(state) {
    return state.recommendId;
  }
};

export default new Vuex.Store({
  state,
  mutations: {
    loading(state, lstate) {
      state.loading = lstate;
    },
    baseData(state, newState) {
      Object.assign(state, newState);
    },
    setAccount(state, account) {
      state.account = account;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setLogin(state, status) {
      state.isLogin = status;
    },
    setDialogVisible(state, status) {
      state.dialogVisible = {
        ...state.dialogVisible,
        ...status
      };
    },
    setRecommendId(state, id) {
      state.recommendId = id;
    }
  },
  actions: {
    setBase({ commit }, data) {
      commit("baseData", data);
    },
    loading({ commit }, data) {
      commit("loading", data);
    },
    setAccount({ commit }, data) {
      commit("setAccount", data);
    },
    setUserInfo({ commit }, data) {
      commit("setUserInfo", data);
    },
    setLogin({ commit }, data) {
      commit("setLogin", data);
    },
    setDialogVisible({ commit }, data) {
      commit("setDialogVisible", data);
    },
    setRecommendId({ commit }, data) {
      commit("setRecommendId", data);
    }
  },
  getters
});
