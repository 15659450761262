import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import cancelClass from "@/common/cancelToken";
import axios from "axios";

//load nprogress
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //required

const language = process.env.VUE_APP_I18N || "zh-CN";
const customLangs = require(`assets/i18n/${language}.json`);

NProgress.configure({
  easing: "ease", //动画方式
  speed: 500, //递增进度条的速度
  showSpinner: false, //是否显示加载ico
  trickleSpeed: 200, //自动递增间隔
  minimum: 0.3 //初始化时的最小百分比
});

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  cancelClass.cancelRequest.source.cancel &&
    cancelClass.cancelRequest.source.cancel("请求取消");
  cancelClass.cancelRequest.source = axios.CancelToken.source();

  NProgress.start(); // show progress bar before route

  document.getElementById("public_title").innerHTML =
    to.meta.pagetitle === undefined
      ? customLangs["page_tttt"]
      : to.meta.pagetitle;

  // eslint-disable-next-line no-undef
  if (window._hmt) {
    if (to.path) {
      // eslint-disable-next-line no-undef
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }

  const userId = localStorage.getItem("userid");

  if (userId && to.name === "home") {
    next({
      path: "/center"
    });
  } else if (userId || !to.meta.requiresAuth) {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: to.fullPath, showLogin: true, rdi: Date.now() }
    });
  }
});

router.afterEach(() => {
  NProgress.done(); // hide progress bar after router
});

export default router;
