import { mock, Random } from "mockjs";

mock(/getBase/, () => {
  return {};
});

mock(/api\/v1\/Login/, "post", {
  code: 200,
  data: {
    id: Random.id(),
    userName: Random.cname(),
    name: Random.cname(),
    identifierCode: Random.id(), //系统标识
    recommendedCode: "xuYY", // 专属推荐码
    avatarUrl: "https://picsum.photos/60/60", //头像
    level: "L1", //会员等级，可能L1代表普通会员
    balance: "@integer(100,1000)", //账户余额（金币）
    credit: "@integer(100,10000)", //信用值
    gender: "男", //性别
    birthday: 1988 - 10 - 22, //出生年月
    provinceID: 1, //当前居住省份
    cityID: 1, //当前居住城市
    districtID: 1, //当前居住区（县）
    maritalStatusID: 0, //婚姻状况
    educationID: 0, //学历
    occupationStatusID: 0, //工作状态
    positionID: 0, //职位
    personalIncomeID: 0, //个人月收入
    householdIncomeID: 0, //家庭月收入
    brandIDOfCar: 0, //常用汽车品牌
    ModelIDOfCar: 0, //常用汽车型号
    purchasedYearOfCar: 0, //常用汽车购置年份
    parnetsOfChilden: false, //是否小孩父母
    birthYearOfYoungChild: 2016 //最小小孩出生年份
  }
});

mock(/\/api\/v1\/Account/, {
  code: 200,
  data: {
    id: Random.id(),
    userName: Random.cname(),
    name: Random.cname(),
    identifierCode: Random.id(), //系统标识
    recommendedCode: "xuYY", // 专属推荐码
    avatarUrl: "https://picsum.photos/60/60", //头像
    level: "L1", //会员等级，可能L1代表普通会员
    balance: "@integer(100,1000)", //账户余额（金币）
    credit: "@integer(100,10000)", //信用值
    gender: "男", //性别
    birthday: 1988 - 10 - 22, //出生年月
    provinceID: 1, //当前居住省份
    cityID: 1, //当前居住城市
    districtID: 1, //当前居住区（县）
    maritalStatusID: 0, //婚姻状况
    educationID: 0, //学历
    occupationStatusID: 0, //工作状态
    positionID: 0, //职位
    personalIncomeID: 0, //个人月收入
    householdIncomeID: 0, //家庭月收入
    brandIDOfCar: 0, //常用汽车品牌
    ModelIDOfCar: 0, //常用汽车型号
    purchasedYearOfCar: 0, //常用汽车购置年份
    parnetsOfChilden: false, //是否小孩父母
    birthYearOfYoungChild: 2016 //最小小孩出生年份
  }
});

const changes = mock({
  "list|15-20": [
    {
      id: "@id",
      userName: "@cname",
      avatarUrl: "https://picsum.photos/60/60", //用户头像
      dateline: '@datetime("hh点mm分")', // 完成时间
      amount: "@integer(100, 10000)", //兑换数量（金币）
      surveyTitle: "@ctitle(5, 15)", //问卷名称
      surveyType: "@cword(5)"
    }
  ]
});
mock(/api\/v1\/Changes/, {
  code: 200,
  data: () => {
    return changes.list;
  }
});

mock(/api\/v1\/Completes/, {
  code: 200,
  data: {
    "list|10-20": [
      {
        id: Random.id(),
        userName: Random.cname(),
        avatarUrl: Random.image("50x50"), //用户头像
        dateline: Random.datetime("yyyy-MM-dd hh:mm"), // 完成时间
        surveyTitle: Random.ctitle(5, 13), //问卷名称
        surveyType: "商业调查"
      }
    ]
  }
});

mock(/api\/v1\/RegistryCode/, {
  code: 200,
  data: {}
});

mock(/\/api\/v1\/Registered/, {
  code: 200,
  data: {}
});

mock(/\/api\/v1\/Registry/, {
  code: 200,
  data: {}
});
mock(/\/api\/v1\/GeetestCode/, {
  code: 200,
  data: {
    success: 1,
    gt: "441272228139dcf0fe93e97f9046a8f5",
    challenge: "7df1ef75b0e184af44109ab52560a1a8",
    new_captcha: true
  },
  errors: []
});
