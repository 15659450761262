<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getIEVersion } from "common/util";
export default {
  name: "app",
  data() {
    return {
      loading: true
    };
  },
  watch: {
    $route: "changeActive"
  },
  async beforeCreate() {
    const userid = localStorage.getItem("userid");

    const ieVersion = getIEVersion();
    if (ieVersion && ieVersion < 10 && ieVersion > 0) {
      this.$router.push({ path: "/brower-update" });
      return;
    }

    if (userid && !this.isLogin) {
      const res = await this.$http.get("/api/v1/Accounts/" + userid);
      if (res.status === 200 && res.data.id) {
        this.setLogin(true);
        this.setAccount(res.data);
        this.$http.get("api/v1/Users/" + userid).then(res => {
          if (res.status === 200) {
            this.setUserInfo(res.data);
            if (!res.data.birthday || !res.data.gender) {
              this.$alert(this.$t("userinfo_tip2"), {
                title: this.$t("tip"),
                confirmButtonText: this.$t("immediately_go"),
                type: "warning",
                callback: () => {
                  this.$router.push({
                    path: "/center/setting",
                    query: { tab: "user" }
                  });
                }
              });
            }
          }
        });
      } else {
        this.setLogin(false);
        this.setAccount({});
        localStorage.setItem("userid", "");
        if (this.$route.meta.requiresAuth) {
          this.$router.push({ path: "/", query: { showLogin: true } });
        }
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapActions([
      "setBase",
      "setLogin",
      "setAccount",
      "setUserInfo",
      "setDialogVisible"
    ]),
    changeActive() {
      if (this.$route.query.showLogin) {
        this.setDialogVisible({ login: true });
      }
    }
  },
  computed: {
    ...mapGetters(["isLogin", "recommendId"])
  }
};
</script>
<style src="./app.scss" lang="scss" />
