import Vue from "vue";
import { Time } from "../common/util";
/**
 * 相对时间指令
 */
Vue.directive("relative-time", {
  bind: function(el, binding) {
    el.innerHTML = Time.transform(binding.value);
    el._relativeTime = setInterval(function() {
      el.innerHTML = Time.transform(binding.value);
    }, 60000); //每分钟，刷新一次
  },
  unbind: function(el) {
    clearInterval(el._relativeTime);
    delete el._relativeTime;
  }
});
