import Vue from "vue";
import "./http";

if (process.env.VUE_APP_MOCK === "true") {
  require("./mock");
}

Vue.prototype.$copy = function(value) {
  this.$copyText(`${value}`).then(
    () => {
      this.$message({
        message: this.$t("copy_success"),
        type: "success"
      });
    },
    () => {
      this.$message.error(this.$t("copy_failed"));
    }
  );
};
